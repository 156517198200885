import * as React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

///import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';


const IndexPage = () => {
  const data = useStaticQuery(graphql`query MyQuery {
    allFile(
      filter: {relativeDirectory: {eq: "carousel"}, extension: {eq: "png"}}
      sort: {fields: base}
    ) {
      edges {
        node {
          id
          publicURL
          rdev
          relativeDirectory
          relativePath
          base
          absolutePath
          dir
          ext
          extension
        }
      }
    }
  }
  `)
  const carouselImages = data.allFile.edges;
  return (
  <Layout>

    

    <Seo title="Home" />
    <Carousel autoPlay="true" style={{}} showThumbs={false} infiniteLoop={true}>
      {carouselImages.map(({node}) => (
        <div key={node.publicURL} style={{objectFit: "cover", borderRadius: "50%", padding: `1.45rem 0`,}}>
          <img
            src={node.publicURL}
            formats={["auto", "png"]}
            alt="The long tradition of Panizza-ing"
            style={{ objectFit: "cover", borderRadius: "12%", height: "50%", width: "50%" }}
          />
        </div>
      ))
      }
    </Carousel>
    <h1 style={{textAlign: "center", fontFamily:"Georgia"}}>A level of "quality" "Guaranteed"</h1>
    <span style={{display: "flex", fontFamily:"Georgia", maxWidth: "auto", justifyContent: "center"}}>
      More people eat Panizzas with Papa Panizzas than any other restaurant! 
      <br/>
We strive to bring quality and often non-expired food to all our customers.
      new line
    </span>
    <h1 style={{textAlign: "center", fontFamily:"Georgia", padding: "2rem 0 0 0"}}>What is a Panizza?</h1>
    <span style={{display: "flex", fontFamily:"Georgia", maxWidth: "auto", justifyContent: "center"}}>
      I don't want to talk to you. Go away from my website right now.
    </span>
    <h1 style={{textAlign: "center", fontFamily:"Georgia", padding: "2rem 0 0 0"}}>No customer is too small!</h1>
    <span style={{display: "flex", fontFamily:"Georgia", maxWidth: "auto", justifyContent: "center"}}>
Breast milk stand aside! 
Panizzas are the perfect food for your newborn and a complete diet all the way up until your 100th year!
    </span>

  </Layout>
)
    }

export default IndexPage
